<template>
    <div class="shadow-xl-white px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="space-y-6 sm:space-y-5">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-2">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Think Thoroughly!</h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        These changes are irreversible, please handle them responsibly.
                    </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-1">
                    <div>
                        <div class="grid grid-cols-3 gap-6">
                            <div class="col-span-3 sm:col-span-3">
<!--                                <label class="block text-sm font-medium leading-5 text-gray-700">-->
<!--                                    Deletion-->
<!--                                </label>-->
                                <div class="mt-1 flex rounded-md">
                                    <button @click="showAlertModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:shadow-outline-red focus:border-red-600 active:bg-red-600 transition duration-150 ease-in-out">
                                        <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <!--                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.08296 9H6.02863C6.11783 7.45361 6.41228 6.02907 6.86644 4.88228C5.41752 5.77135 4.37513 7.25848 4.08296 9ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 4C9.92395 4 9.76787 4.03173 9.5347 4.26184C9.29723 4.4962 9.03751 4.8849 8.79782 5.44417C8.40914 6.3511 8.12491 7.58559 8.03237 9H11.9676C11.8751 7.58559 11.5909 6.3511 11.2022 5.44417C10.9625 4.8849 10.7028 4.4962 10.4653 4.26184C10.2321 4.03173 10.076 4 10 4ZM13.9714 9C13.8822 7.45361 13.5877 6.02907 13.1336 4.88228C14.5825 5.77135 15.6249 7.25848 15.917 9H13.9714ZM11.9676 11H8.03237C8.12491 12.4144 8.40914 13.6489 8.79782 14.5558C9.03751 15.1151 9.29723 15.5038 9.5347 15.7382C9.76787 15.9683 9.92395 16 10 16C10.076 16 10.2321 15.9683 10.4653 15.7382C10.7028 15.5038 10.9625 15.1151 11.2022 14.5558C11.5909 13.6489 11.8751 12.4144 11.9676 11ZM13.1336 15.1177C13.5877 13.9709 13.8822 12.5464 13.9714 11H15.917C15.6249 12.7415 14.5825 14.2287 13.1336 15.1177ZM6.86644 15.1177C6.41228 13.9709 6.11783 12.5464 6.02863 11H4.08296C4.37513 12.7415 5.41752 14.2287 6.86644 15.1177Z"/>-->
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z" />
                                        </svg>
                                        Delete Community
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <alert-modal v-if="false" :open="showAlertModal" v-on:close="closeAlertModal" v-on:reset="deleteCommunity">
            <template v-slot:header>
                Community deletion requested!
            </template>
            <template v-slot:text>
                You've requested to delete an article. Please confirm if you meant to take this action. This is irreversible!
            </template>
            <template v-slot:button>
                Delete
            </template>
        </alert-modal>



        <alertNotification v-if="false" class="z-100" style="z-index: 50" :open="showStatusAlertNotification" notificationType="alert">
            <template v-slot:header>
                Can't change integration status.
            </template>
            <template v-slot:text>
                Changing integration status has failed. Please make sure your connection is available, or try again later, might be our problem :( .
            </template>
        </alertNotification>
    </div>
</template>

<script>
    import AlertModal from "@/components/AlertModal";
    import AlertNotification from '@/components/ActionNotification';
    import * as axios from "@/axios-auth";
    import {mapGetters, mapState} from "vuex";
    export default {
        name: "CommunitySettingsDelete",
        components: {AlertModal, AlertNotification},
        data(){
            return {
                showAlertModal: false,
                showStatusAlertNotification: false,
            }
        },
        methods: {
            closeAlertModal(){
                this.showAlertModal = false
            },
            deleteCommunity(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });

                axios.jigo.delete(`/v2/community/${this.communityOwner}/${this.communityName}`)
                .then(resp => {
                    if(resp.status === 204){
                        this.$router.push({name:'home'})
                    } else {
                        console.error("An Error occurred trying to delete a community", resp.data)
                    }
                    loader.hide()
                }).catch(err => {
                    console.error("An Error occurred trying to delete a community", err.response.data)
                    loader.hide()
                })
            },
            loaderCanceled(){
                console.log("Loader Canceled")
            },
        },
        computed: {
            ...mapGetters(['getCommunities', 'getUsername']),
            ...mapState({
                integrations: state => state.community.integrations
            }),
            fullCommunityName() {
                return `${this.communityOwner}/${this.communityName}`
            },
            hasAccess(){
                return (this.getCommunities.includes(this.fullCommunityName) || this.isOwner)
            },
            isOwner(){
                return (this.getUsername === this.communityOwner)
            }
        }
    }
</script>

<style scoped>

</style>
